import React, { useEffect } from "react";

export const Datenschutz = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      Datenschutz
      <div>Datenschutz</div>
    </div>
  );
};
