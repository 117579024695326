import React, { useState, useEffect, useRef } from "react";

export const WaveSVG = () => {
  const waveRef = useRef<HTMLDivElement>(null);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      if (waveRef.current) {
        const scrollPos = window.scrollY;

        const scrollPercentage = Math.min(scrollPos / 1500, 1);
        const newOffset = scrollPercentage * 1500 * 0.5;

        setOffset(newOffset);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div
      className="wave-container"
      style={{
        height: offset + 100,
      }}
    >
      <div
        className="wave"
        ref={waveRef}
        style={{
          transform: `translateY(-${offset + 50}px) translateX(-${
            offset + 50
          }px)`,
          marginTop: "clamp(0px, 100vh, 5rem)",
        }}
      >
        <svg
          id="wave"
          viewBox="0 0 1440 360"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0">
              <stop stopColor="rgb(17, 63, 112)" offset="10%" />
              <stop stopColor="rgba(255, 255, 0, 0.5)" offset="100%" />
            </linearGradient>
          </defs>
          <path
            fill="url(#sw-gradient-0)"
            d="M0,337L17.1,316.5C34.3,296,69,255,103,261.8C137.1,269,171,323,206,316.5C240,310,274,241,309,220.8C342.9,200,377,228,411,241.3C445.7,255,480,255,514,220.8C548.6,187,583,118,617,91C651.4,64,686,77,720,104.7C754.3,132,789,173,823,193.5C857.1,214,891,214,926,186.7C960,159,994,105,1029,104.7C1062.9,105,1097,159,1131,159.3C1165.7,159,1200,105,1234,111.5C1268.6,118,1303,187,1337,207.2C1371.4,228,1406,200,1440,207.2V360H0Z"
          ></path>
        </svg>
        <div className="blue-volume"></div>
      </div>
    </div>
  );
};
