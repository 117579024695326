import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "../styles/navbar.scss";

export const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [show, setShow] = useState(true);
  const [hasBackground, setHasBackground] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const body = document.body;
    const html = document.documentElement;

    if (isMenuOpen) {
      body.style.overflowY = "hidden";
      html.style.overflowY = "hidden";
    } else {
      body.style.overflowY = "initial";
      html.style.overflowY = "initial";
    }

    return () => {
      body.style.overflowY = "initial";
      html.style.overflowY = "initial";
    };
  }, [isMenuOpen]);

  return (
    <div
      className={`navbar-container ${
        hasBackground ? "showbackground" : "hidebackground"
      } ${show ? "active" : "hidden"}`}
    >
      <div className="navbar-width">
        <div className="navbar">
          <div className="navbar-big-pill">
            <NavLink className="navbar-left" to="/">
              <p>elias vorderegger</p>
            </NavLink>
            <NavLink className="navbar-right" to="/">
              home
            </NavLink>
            <NavLink className="navbar-right" to="/about">
              about
            </NavLink>
            <NavLink className="navbar-right" to="/contact">
              contact
            </NavLink>
          </div>
        </div>
        <div className="navbar-mobile">
          <div className="navbar-big-pill">
            <NavLink className="navbar-left" to="/">
              <p>elias vorderegger</p>
            </NavLink>
            <svg
              className={`hamburger ${isMenuOpen ? "hidden" : ""}`}
              width="35"
              height="35"
              viewBox="0 0 24 24"
              onClick={handleMenuToggle}
            >
              <path
                className="hamburger-hover"
                d="M12 18H3V16H12V18ZM21 13H3V11H21V13ZM21 8H12V6H21V8Z"
                fill="white"
              />
            </svg>
            <svg
              className={`cross ${!isMenuOpen ? "hidden" : ""}`}
              width="35"
              height="35"
              viewBox="0 0 24 24"
              onClick={handleMenuToggle}
            >
              <path
                className="cross-hover"
                d="M6 6L18 18M6 18L18 6"
                stroke="white"
                strokeWidth="2"
                fill="transparent"
              />
            </svg>

            <div
              className={`dropdown ${!isMenuOpen ? "hidden" : ""}`}
              onClick={handleMenuToggle}
            >
              <NavLink className="nav-mobile" to="/" onClick={handleLinkClick}>
                home
              </NavLink>
              <NavLink
                className="nav-mobile"
                to="/about"
                onClick={handleLinkClick}
              >
                about
              </NavLink>
              <NavLink
                className="nav-mobile"
                to="/contact"
                onClick={handleLinkClick}
              >
                contact
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
