import React from "react";
import "../styles/footer.scss";
import { MountainTransition } from "../components/MountainTransition";
import { NavLink } from "react-router-dom";

export const Footer = () => {
  return (
    <div>
      <div className="footer">
        <div className="footer-icons">
          <NavLink className="footer-link" to="/">
            home
          </NavLink>
          <a
            className="footer-link"
            href="https://github.com/eliasvor"
            target="_blank"
          >
            github
          </a>
        </div>
        Made with <span>&#9829;</span> in Austria
      </div>
    </div>
  );
};
