import React, { useEffect } from "react";
import "../styles/about.scss";
import { MountainTransition } from "../components/MountainTransition";

export const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="about-wrapper">
      <h2>Still working on that...</h2>
      <MountainTransition />
    </div>
  );
};
