import React, { useEffect } from "react";
import ContactForm from "../components/ContactForm";
import "../styles/contact.scss";
import { MountainTransition } from "../components/MountainTransition";

export const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="contact-wrapper">
      <div className="contact">
        <div className="width-restrict">
          <h1>contact me</h1>
          <ContactForm />
        </div>
      </div>
      <MountainTransition />
    </div>
  );
};
