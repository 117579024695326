import React, { useState } from "react";
import emailjs from "emailjs-com";

interface ContactFormProps {}

const ContactForm: React.FC<ContactFormProps> = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<"idle" | "success" | "error">("idle");

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setStatus("idle");

    emailjs
      .send(
        "service_8eabxmf",
        "template_kvl107x",
        formData,
        "cg0RWJuEFGhWmMajF"
      )
      .then(
        (response) => {
          setLoading(false);
          setStatus("success");
          setFormData({ name: "", email: "", message: "" });
        },
        (err) => {
          setLoading(false);
          setStatus("error");
        }
      );
  };

  const renderButtonText = () => {
    if (loading) return "sending...";
    if (status === "success") return "success";
    if (status === "error") return "failed to send";
    return "send";
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-elements">
        <div className="element">
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="name"
            required
          />
          <p>name</p>
        </div>
        <div className="element">
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="email"
            required
          />
          <p>email</p>
        </div>
        <div className="element">
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            placeholder="message"
            required
          />
          <p>message</p>
        </div>
      </div>
      <button
        type="submit"
        className={`send-button ${loading ? "loading" : ""} ${
          status === "success" ? "success" : ""
        } ${status === "error" ? "error" : ""}`}
        disabled={loading}
      >
        {renderButtonText()}
      </button>
    </form>
  );
};

export default ContactForm;
