import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Navbar } from "./nav-elements/Navbar";
import { Footer } from "./nav-elements/Footer";
import { Home } from "./pages/Home";
import { Impressum } from "./pages/Impressum";
import { Datenschutz } from "./pages/Datenschutz";
import "./styles/App.scss";
import { About } from "./pages/About";
import { Contact } from "./pages/Contact";
import { Analytics } from "@vercel/analytics/react";

function App() {
  return (
    <BrowserRouter>
      <Analytics />
      <div className="App">
        <Navbar />
        <div className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/datenschutz" element={<Datenschutz />} />
            <Route path="/impressum" element={<Impressum />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
