import React from "react";
import { NavLink } from "react-router-dom";
import "../styles/contact-button.scss";
import { WeuiArrowFilled } from "../components/emoji/arrow";

export const ContactButton = () => {
  return (
    <div className="contact-button">
      <NavLink to={"/contact"}>
        <button className="wavy-button">
          contact me
          <WeuiArrowFilled />
        </button>
      </NavLink>
    </div>
  );
};
