import React from "react";
import { NavLink } from "react-router-dom";
import { WeuiArrowFilled } from "./emoji/arrow";

export const AboutBanner = () => {
  return (
    <NavLink className={"about-banner-link"} to={"/about"}>
      <div className="about-banner-margin">
        <p>{`Learn more about me`}</p>
        <WeuiArrowFilled />
      </div>
    </NavLink>
  );
};
